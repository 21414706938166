import { EventEmitter, Injectable } from "@angular/core";
import * as ExcelProper from "exceljs";
import { Workbook, Worksheet } from "exceljs";
import * as Excel from "exceljs/dist/exceljs";
import * as FileSaver from "file-saver";
import { NgxSpinnerService } from "ngx-spinner";
import { MortalityModel } from "../../core/model/mortality-table/mortality-table.model";
import { Accident } from "../../core/model/quotation/quotation.model";
import { aplicateFormat } from "../../views/quotation/quotation/utils/utilsMethodsCalculate";

interface ExportExcelRequest {
	fileName: string;
	data: Array<any>;
}


interface ResponseLoadExcelI {
	accidents: Accident[];
	success: boolean;
}

@Injectable()
export class FileExportService {
	constructor() {
	}

	settings: any;

	private createWorkbook(): ExcelProper.Workbook {
		const workbook: ExcelProper.Workbook = new Excel.Workbook();
		workbook.creator = "Web";
		workbook.lastModifiedBy = "Web";
		workbook.created = new Date();
		workbook.modified = new Date();
		return workbook;
	}

	private addWorkSheet(workbook: ExcelProper.Workbook, fileName: string) {
		workbook.addWorksheet(fileName, {
			views: [
				{
					activeCell: "A1",
					showGridLines: true
				}
			]
		});
	}

	public importData(file: any, event: EventEmitter<ResponseLoadExcelI>, _spinner: NgxSpinnerService) {
		const workbook: ExcelProper.Workbook = new Excel.Workbook();

		workbook.xlsx.load(file).then(() => {
			const sheet: Worksheet = workbook.worksheets[0];
			const rowCant: number = sheet.actualRowCount;
			const accidents: Accident[] = [];
			let row;
			for (let i = 10; i <= rowCant; i++) {
				row = (sheet.getRow(i).values as Array<any>).filter(x => x !== undefined);
				if (row.length > 0) {
					const accident: Accident = {
						type: row[0],
						initialDate: row[1],
						endDate: row[2],
						frecuency: row[3],
						payrollMonthly: aplicateFormat(row[4]),
						rateNet: aplicateFormat(row[5] * 100, 4),
						netPremiumMonthly: aplicateFormat(row[6].result),
						netPremiumPolicy: aplicateFormat(row[7].result),
						paidAccidents: aplicateFormat(row[8]),
						rateAccidents: aplicateFormat(row[9].result * 100)
					};
					accidents.push(accident);
				}
			}
			event.emit({ accidents: accidents, success: true });
		}).catch(reason => {
			if (!!reason) {
				event.emit({ accidents: [], success: false });
				_spinner.hide();
			}
		});
	}

	private addTableHeader(workbook: ExcelProper.Workbook, colHeaders: string[], numberSheet: number, numberTableHeader: number) {
		const sheet = workbook.getWorksheet(numberSheet);
		const xcolHeaders = [
			{ key: "age", width: 20 },
			{ key: "male", width: 50 },
			{ key: "female", width: 50 },
			{ key: "mix", width: 60 }
		];
		sheet.views = [
			{ state: "frozen", xSplit: 3, ySplit: 4 }
		];
		sheet.addRow("");
		sheet.getRow(numberTableHeader).values = colHeaders;
		sheet.columns = xcolHeaders;
	}

	private addTableHeaderWorkers(workbook: ExcelProper.Workbook, colHeaders: string[], numberSheet: number, numberTableHeader: number) {
		const sheet = workbook.getWorksheet(numberSheet);
		const xcolHeaders = [
			{ key: "documentType", width: 20 },
			{ key: "documentNumber", width: 20 },
			{ key: "surname1", width: 20 },
			{ key: "surname2", width: 20 },
			{ key: "name", width: 20 },
			{ key: "gender", width: 10 },
			{ key: "birthDate", width: 30 },
			{ key: "workerType", width: 30 },
			{ key: "salary", width: 20 },
			{ key: "status", width: 20 },
			{ key: "campus", width: 20 },
			{ key: "movementType", width: 20 },
			{ key: "countryOfBirth", width: 20 }
		];
		sheet.views = [
			{ state: "frozen", xSplit: 3, ySplit: 6 }
		];
		sheet.addRow("");
		sheet.getRow(numberTableHeader).values = colHeaders;
		sheet.columns = xcolHeaders;
	}


	private formatSheet(workbook: ExcelProper.Workbook, numberSheet: number, numberTableHeader: number) {
		const sheet = workbook.getWorksheet(numberSheet);
		const font = { name: "Calibri", size: 10 };
		sheet.eachRow(function(row, rowNumber) {
			if (rowNumber >= numberTableHeader) {
				row.eachCell({ includeEmpty: true }, function(cell, cellNumber) {
					sheet.getCell(cell.address.toString()).font = font;

					sheet.getCell(cell.address.toString()).alignment = {
						wrapText: true,
						vertical: "middle"
					};

					if (rowNumber === numberTableHeader) {
						sheet.getCell(cell.address.toString()).alignment = {
							wrapText: true,
							horizontal: "center"
						};
						sheet.getCell(cell.address.toString()).font = {
							bold: true,
							color: { argb: "FFFFFF" }
						};
					}

					sheet.getCell(cell.address.toString()).fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {
							argb: rowNumber === numberTableHeader ? "2b0d61"
								: rowNumber > numberTableHeader && Number(cell.col) < numberTableHeader ? "f1f1f1" : ""
						}
					};

					sheet.getCell(cell.address.toString()).border = {
						top: { style: "thin", color: { argb: "000000" } },
						left: { style: "thin", color: { argb: "000000" } },
						bottom: { style: "thin", color: { argb: "000000" } },
						right: { style: "thin", color: { argb: "000000" } }
					};
				});
			}

		});
	}

	public exportMortalityMode(items: MortalityModel[], mHeaderColl: string[]) {
		const workbook = this.createWorkbook();
		this.addWorkSheet(workbook, "Tabla de mortalidad");
		this.addTableHeader(workbook, mHeaderColl, 1, 3);
		this.formatSheet(workbook, 1, 3);
		this.addMortalityModelDataTable(workbook, items, 1);
		this.pushFileToBrowser(workbook, "Tabla de mortalidad");
	}

	private addMortalityModelDataTable(workbook: ExcelProper.Workbook, items: MortalityModel[], numberSheet: number) {
		const sheet = workbook.getWorksheet(numberSheet);
		const data = this.getMortalityModelData(items);
		sheet.addRows(data);
	}

	private addWorkersModelDataTable(workbook: ExcelProper.Workbook, items: Worker[], numberSheet: number) {
		const sheet = workbook.getWorksheet(numberSheet);
		const data = this.getWorkerModelData(items);
		sheet.addRows(data);
	}

	private getMortalityModelData(items: MortalityModel[]) {
		const dataTable = [];
		items.forEach((item, index) => {
			const rowTable = [];
			rowTable.push(index);
			rowTable.push(item.male.toFixed(8));
			rowTable.push(item.female.toFixed(8));
			rowTable.push(item.mix.toFixed(8));
			dataTable.push(rowTable);
		});
		return dataTable;
	}

	private getWorkerModelData(items: any[]) {
		const dataTable = [];
		items.forEach(item => {
			const rowTable = [];
			rowTable.push(item.documentType);
			rowTable.push(item.documentNumber);
			rowTable.push(item.surname1);
			rowTable.push(item.surname2);
			rowTable.push(item.name);
			rowTable.push(item.gender.toUpperCase());
			rowTable.push(new Date(item.birthDate));
			rowTable.push(item.workerType.description);
			rowTable.push(item.salary);
			rowTable.push(this.getStatus(item.status));
			rowTable.push(item.campus);
			rowTable.push(item.movementType);
			rowTable.push(item.countryOfBirth);
			dataTable.push(rowTable);
		});
		return dataTable;
	}

	private getStatus(cad: string) {
		switch (cad.toUpperCase()) {
			case "ACTIVE": {
				return "Activo";
			}
			case "INCLUDED": {
				return "Incluido";
			}
			case "VALID": {
				return "Vigente";
			}
			default: {
				return "Activo";
			}
		}
	}

	public exportPayrollWorkers(items: any[], mHeaderColl: string[]) {
		const workbook = this.createWorkbook();
		this.addWorkSheet(workbook, "Planilla de trabajadores");
		this.addTableHeaderWorkers(workbook, mHeaderColl, 1, 3);
		this.formatSheet(workbook, 1, 3);
		this.addWorkersModelDataTable(workbook, items, 1);
		this.pushFileToBrowser(workbook, "Planilla de trabajadores");
	}

	public excelExport(fileName: string, header: string[], index: string[], data: any[]) {
		const workbook: Workbook = new Workbook();
		workbook.creator = "Web";
		workbook.lastModifiedBy = "Web";
		workbook.created = new Date();
		workbook.modified = new Date();
		workbook.addWorksheet(fileName, {
			views: [
				{
					activeCell: "A1",
					showGridLines: true
				}
			]
		});
		const sheet = workbook.getWorksheet(1);
		const font = { name: "Arial", size: 10 };

		const head1 = [fileName];
		const titleRow = sheet.addRow(head1);
		titleRow.font = font;
		titleRow.font = {
			size: 10,
			underline: false,
			bold: true,
			color: { argb: "000000" }
		};

		const xcolHeaders = [
			{ key: "gri", width: 50 },
			{ key: "lev", width: 20 },
			{ key: "uni", width: 20 },
			{ key: "lev1", width: 9 },
			{ key: "lev2", width: 11 },
			{ key: "lev3", width: 20 },
			{ key: "lev4", width: 10 },
			{ key: "lev5", width: 10 }
		];

		sheet.addRow("");
		sheet.getRow(3).values = header;
		sheet.columns = xcolHeaders;
		sheet.addRows(this.FormatData(index, data));

		sheet.eachRow(function(row, rowNumber) {
			if (rowNumber >= 3) {
				row.eachCell({ includeEmpty: true }, function(cell, cellNumber) {
					sheet.getCell(cell.address.toString()).font = font;

					sheet.getCell(cell.address.toString()).alignment = {
						wrapText: true,
						vertical: "middle"
					};

					if (rowNumber === 3) {
						sheet.getCell(cell.address.toString()).alignment = {
							horizontal: "center"
						};
						sheet.getCell(cell.address.toString()).font = {
							bold: true
						};
					}

					sheet.getCell(cell.address.toString()).fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: rowNumber === 3 ? "D3D3D3" : "" }
					};

					sheet.getCell(cell.address.toString()).border = {
						top: { style: "thin" },
						left: { style: "thin" },
						bottom: { style: "thin" },
						right: { style: "thin" }
					};
				});
			}
		});
		this.pushFileToBrowser(workbook);
	}

	pushFileToBrowser(workbook: ExcelProper.Workbook, nombreFile?: string) {
		workbook.xlsx.writeBuffer().then(dataw => {
			const blob = new Blob([dataw], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
			let fileN = "";
			if (nombreFile) {
				fileN = nombreFile + ".xlsx";
			} else {
				const crypto = window.crypto;
				const array = new Uint32Array(2);
				const randomValues = crypto.getRandomValues(array);
				fileN = randomValues[0].toString() + randomValues[1].toString() + ".xlsx";
			}

			const url = window.URL.createObjectURL(blob);
			const anchor = document.createElement("a");
			anchor.href = url;
			anchor.download = fileN;
			anchor.click();
			setTimeout(function() {
				window.URL.revokeObjectURL(url);
			}, 0);
		});
	}

	private FormatData(index: string[], data: any[]): any[] {
		const result: any[] = [];
		data.forEach((value, index1) => {
			const row: any[] = [];
			index.forEach((value1, index2) => {
				if (typeof value[value1] === "object") {
					if (value[value1] !== null) {
						if (value1 === "locations") {
							let chain: string = "";
							let first: boolean = true;
							(value[value1] as Array<any>).forEach(value2 => {
								if (first) {
									first = false;
									chain += value2.description;
								} else {
									chain += "/" + value2.description;
								}
							});
							row.push(chain);
						} else if (value1.toLowerCase().indexOf("date")) {
							if (value[value1] != null) {
								row.push(value[value1].format("DD/MM/YYYY HH:mm:ss"));
							}
						} else {
							row.push(value[value1].description);
						}
					} else {
						row.push(value[value1]);
					}
				} else {
					switch (typeof value[value1]) {
						case "boolean":
							if (value[value1]) {
								row.push("SI");
							} else {
								row.push("NO");
							}
							break;
						default:
							if (value1 === "currency") {
								if (value[value1] === "PEN") {
									row.push("SOLES");
								} else {
									row.push("USD");
								}
							} else {
								row.push(value[value1]);
							}
					}
				}
			});
			result.push(row);
		});
		return result;
	}

	private isValidObjectList(data: Array<any>): boolean {
		return !(Object.keys(data)[0] ?? []).length;
	}

	private get stylesItemBodyExcel(): {
		fill: any;
		font: any;
		alignment: any;
		border: any;
	} {
		return {
			fill: {
				type: "pattern",
				pattern: "solid",
				fgColor: {
					argb: "F4F4F4"
				}
			},
			font: {
				size: 12,
				name: "Calibri"
			},
			alignment: {
				horizontal: "center",
				vertical: "middle"
			},
			border: {
				top: {
					color: {
						argb: "cccccc"
					},
					style: "thin"
				},
				right: {
					color: {
						argb: "cccccc"
					},
					style: "thin"
				},
				bottom: {
					color: {
						argb: "cccccc"
					},
					style: "thin"
				},
				left: {
					color: {
						argb: "cccccc"
					},
					style: "thin"
				}
			}
		};
	}

	private get stylesItemHeaderExcel(): {
		fill: any;
		font: any;
		alignment: any;
		border: any;
	} {
		return {
			fill: {
				type: "pattern",
				pattern: "solid",
				fgColor: {
					argb: "553d81"
				}
			},
			font: {
				size: 12,
				color: {
					argb: "ffffff"
				},
				name: "Calibri"
			},
			alignment: {
				horizontal: "center",
				vertical: "middle"
			},
			border: {
				bottom: {
					color: {
						argb: "ffffff"
					},
					style: "thick"
				},
				left: {
					color: {
						argb: "ffffff"
					},
					style: "thin"
				}
			}
		};
	}

	private setMedaDataForExportExcel(workBook: Workbook): void {
		workBook.creator = "Protecta Security";
		workBook.created = new Date();
		workBook.modified = new Date();
	}

	private setHeaderForExportExcel(
		workSheet: Worksheet,
		data: Array<any>
	): void {
		const keysFirstObject: Array<any> = Object.keys(data[0]);
		const columns = new Array();
		keysFirstObject.forEach((key: string) => {
			const width = Math.max.apply(
				Math,
				data.map((x: any) => (x[key] instanceof Date ? 13 : `${x[key]}`.length))
			);
			columns.push({
				header: key,
				key: key.replace(" ", "_"),
				width: (width > key.length ? width : key.length) + 3.5
			});
		});

		workSheet.columns = columns;
		workSheet.getRow(1).eachCell((cell) => {
			cell.fill = this.stylesItemHeaderExcel.fill;
			cell.font = this.stylesItemHeaderExcel.font;
			cell.alignment = this.stylesItemHeaderExcel.alignment;
			cell.border = this.stylesItemHeaderExcel.border;
		});
	}

	private setRowsBodyForExportExcel(
		workSheet: Worksheet,
		data: Array<any>
	): void {
		data.forEach((values: any) => {
			const keys = Object.keys(values);
			const array = new Array();

			keys.forEach((key: string) => {
				array.push(values[key]);
			});
			const row = workSheet.addRow(array);
			row.eachCell((cell) => {
				cell.fill = this.stylesItemBodyExcel.fill;
				cell.font = this.stylesItemBodyExcel.font;
				cell.border = this.stylesItemBodyExcel.border;
				cell.alignment = this.stylesItemBodyExcel.alignment;
			});
		});
	}

	private writeDataForExportExcel(
		workBook: Workbook,
		fileName: string
	): Promise<any> {
		return workBook.xlsx.writeBuffer().then((data) => {
			const blob = new Blob([data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			});
			FileSaver.saveAs(blob, `${fileName}_${new Date().getTime()}.xlsx`);
		});
	}

	exportExcel(payload: ExportExcelRequest): Promise<Buffer> {
		const isEmptyData: boolean = this.isValidObjectList(payload.data);

		if (isEmptyData) {
			return;
		}

		const workBook = new Workbook();
		this.setMedaDataForExportExcel(workBook);

		const workSheet = workBook.addWorksheet(payload.fileName);
		workSheet.addRow(undefined);

		this.setHeaderForExportExcel(workSheet, payload.data);
		this.setRowsBodyForExportExcel(workSheet, payload.data);
		return this.writeDataForExportExcel(workBook, payload.fileName);
	}

	async readExcel(file: File): Promise<any[][]> {
		const workbook = new ExcelProper.Workbook();
		const arrayBuffer = await file.arrayBuffer();
		await workbook.xlsx.load(arrayBuffer);

		const data: any[][] = [];
		workbook.eachSheet((worksheet) => {
			worksheet.eachRow((row) => {
				data.push((row.values as any).slice(1));
			});
		});

		return data;
	}
}
