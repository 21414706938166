import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class ProfileService {
	currentProfile = "";

	constructor() {
		this.getCurrentProfile();
	}


	getCurrentProfile() {
		const prefix = "Username";
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookies = decodedCookie.split(";");

		for (let cookie of cookies) {
			cookie = cookie.trim();

			if (cookie.includes(prefix)) {
				const c = cookie.split("=")[1];
				const json = JSON.parse(c);
				try {
					if (json.Username) {
						this.currentProfile = json.Username;
					}
				} catch (e) {
					console.error(e);
				}
			}
		}
	}
}
